/// <reference types="./new_proposal.d.mts" />
import * as $birl from "../../../birl/birl.mjs";
import * as $proposal from "../../../common/data/proposal.mjs";
import * as $qualification_matrix from "../../../common/data/qualification_matrix.mjs";
import * as $question from "../../../common/data/question.mjs";
import * as $spreadsheet from "../../../common/data/spreadsheet.mjs";
import {
  AnswerColumn,
  MultipleChoiceColumn,
  QuestionColumn,
  SectionRow,
  UndefinedColumn,
  UndefinedRow,
  YesNoColumn,
} from "../../../common/data/spreadsheet.mjs";
import * as $status from "../../../common/data/status.mjs";
import * as $translate from "../../../common/data/translate.mjs";
import * as $extra from "../../../common/gleam/list/extra.mjs";
import * as $json from "../../../gleam_json/gleam/json.mjs";
import * as $bool from "../../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../../gleam_stdlib/gleam/dict.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import * as $spreadsheet_selector from "../../data/ui/spreadsheet_selector.mjs";
import * as $form_data from "../../form_data.mjs";
import { Ok, Error, toList, CustomType as $CustomType, makeError, isEqual } from "../../gleam.mjs";
import * as $utils from "../../utils.mjs";
import { find_column_index, find_nth } from "../../utils.mjs";

export class NewProposal extends $CustomType {
  constructor(step, name, due_date, client, collaborators, qualification_matrix, languages, proposal_spreadsheet) {
    super();
    this.step = step;
    this.name = name;
    this.due_date = due_date;
    this.client = client;
    this.collaborators = collaborators;
    this.qualification_matrix = qualification_matrix;
    this.languages = languages;
    this.proposal_spreadsheet = proposal_spreadsheet;
  }
}

export function init() {
  return new NewProposal(
    1,
    "",
    $birl.utc_now(),
    "",
    toList([]),
    new $option.None(),
    toList([]),
    $spreadsheet_selector.new$(),
  );
}

export function update_client(new_proposal, client) {
  return new_proposal.withFields({ client: client });
}

export function update_name(new_proposal, name) {
  return new_proposal.withFields({ name: name });
}

export function update_due_date(new_proposal, date) {
  return new_proposal.withFields({ due_date: date });
}

export function next_step(new_proposal) {
  return new_proposal.withFields({ step: new_proposal.step + 1 });
}

export function update_qualification_matrix_item(new_proposal, id, answer) {
  let $ = new_proposal.qualification_matrix;
  if ($ instanceof $option.None) {
    return new_proposal;
  } else {
    let qualification_matrix = $[0];
    let _pipe = $list.map(
      qualification_matrix,
      (qm) => {
        return $bool.guard(
          qm[0].id !== id,
          qm,
          () => { return [qm[0], answer]; },
        );
      },
    );
    let _pipe$1 = new $option.Some(_pipe);
    return ((qm) => {
      return new_proposal.withFields({ qualification_matrix: qm });
    })(_pipe$1);
  }
}

export function use_qualification_matrix(new_proposal, qualification_matrix) {
  return new_proposal.withFields({
    qualification_matrix: new $option.Some(qualification_matrix)
  });
}

export function add_collaborator(new_proposal, user_id) {
  let collaborators = (() => {
    let _pipe = new_proposal.collaborators;
    let _pipe$1 = $extra.front_insert(_pipe, user_id);
    return $list.unique(_pipe$1);
  })();
  return new_proposal.withFields({ collaborators: collaborators });
}

export function add_language(new_proposal, language) {
  let languages = (() => {
    let _pipe = new_proposal.languages;
    let _pipe$1 = $extra.front_insert(_pipe, language);
    return $list.unique(_pipe$1);
  })();
  return new_proposal.withFields({ languages: languages });
}

export function remove_language(new_proposal, language) {
  let languages = (() => {
    let _pipe = new_proposal.languages;
    return $list.filter(_pipe, (l) => { return !isEqual(l, language); });
  })();
  return new_proposal.withFields({ languages: languages });
}

export function remove_collaborator(new_proposal, user_id) {
  let collaborators = (() => {
    let _pipe = new_proposal.collaborators;
    return $list.filter(_pipe, (u) => { return u !== user_id; });
  })();
  return new_proposal.withFields({ collaborators: collaborators });
}

export function back_to_upload(new_proposal) {
  return new_proposal.withFields({
    step: new_proposal.step - 1,
    proposal_spreadsheet: $spreadsheet_selector.new$()
  });
}

function find_default_dict(dicts, name) {
  let _pipe = $list.key_find(dicts, name);
  return $result.unwrap(_pipe, $dict.new$());
}

function encode_question_metadata(cell, choices, x, y) {
  return $json.object(
    toList([
      ["x", $json.int(x)],
      ["y", $json.int(y)],
      ["question", $json.nullable(cell.value, $json.string)],
      ["choices", $json.array(choices, $json.string)],
    ]),
  );
}

function find_choices_associated_to_question(types, row, cell, x, y) {
  let _pipe = find_column_index(
    $dict.to_list(types),
    new MultipleChoiceColumn(),
  );
  let _pipe$1 = $result.then$(
    _pipe,
    (_capture) => {
      return find_nth(
        $list.map(row, (x) => { return x.dropdown; }),
        _capture,
        0,
      );
    },
  );
  let _pipe$2 = $result.unwrap(_pipe$1, toList([]));
  return ((_capture) => {
    return encode_question_metadata(cell, _capture, x, y);
  })(_pipe$2);
}

function encode_sheet_questions(content, invalid, types) {
  return $list.flatten(
    $list.index_map(
      content,
      (row, y) => {
        return $list.flatten(
          $list.index_map(
            row,
            (cell, x) => {
              let is_invalid = (isEqual(
                (() => {
                  let _pipe = $dict.get(invalid, y);
                  return $result.unwrap(_pipe, new UndefinedRow());
                })(),
                new SectionRow()
              )) || (isEqual(
                (() => {
                  let _pipe = $dict.get(invalid, y);
                  return $result.unwrap(_pipe, new UndefinedRow());
                })(),
                new UndefinedRow()
              ));
              let is_question = isEqual(
                (() => {
                  let _pipe = $dict.get(types, x);
                  return $result.unwrap(_pipe, new UndefinedColumn());
                })(),
                new QuestionColumn()
              );
              return $bool.guard(
                is_invalid,
                toList([]),
                () => {
                  return $bool.guard(
                    !is_question,
                    toList([]),
                    () => {
                      return toList([
                        find_choices_associated_to_question(
                          types,
                          row,
                          cell,
                          x,
                          y,
                        ),
                      ]);
                    },
                  );
                },
              );
            },
          ),
        );
      },
    ),
  );
}

function encode_questions(data, new_proposal) {
  let proposal_spreadsheet = new_proposal.proposal_spreadsheet;
  return $json.preprocessed_array(
    $list.flat_map(
      data,
      (worksheet) => {
        let types = find_default_dict(
          proposal_spreadsheet.columns_type,
          worksheet.name,
        );
        let invalid = find_default_dict(
          proposal_spreadsheet.invalid_lines,
          worksheet.name,
        );
        let questions = encode_sheet_questions(
          worksheet.content,
          invalid,
          types,
        );
        if (questions.hasLength(0)) {
          return toList([]);
        } else {
          let questions$1 = questions;
          return toList([
            $json.object(
              toList([
                ["sheet", $json.string(worksheet.name)],
                ["start_index", $json.int(worksheet.start_index)],
                ["questions", $json.preprocessed_array(questions$1)],
              ]),
            ),
          ]);
        }
      },
    ),
  );
}

export function require_qualification_matrix(new_proposal, next) {
  let $ = new_proposal.qualification_matrix;
  if ($ instanceof $option.None) {
    throw makeError(
      "panic",
      "data/ui/new_proposal",
      263,
      "require_qualification_matrix",
      "Qualification matrix not selected",
      {}
    )
  } else {
    let qualification_matrix = $[0];
    return next(qualification_matrix);
  }
}

const valid_columns = /* @__PURE__ */ toList([
  /* @__PURE__ */ new AnswerColumn(),
  /* @__PURE__ */ new YesNoColumn(),
  /* @__PURE__ */ new MultipleChoiceColumn(),
]);

function encode_proposal(new_proposal, org_id, user_id, xlsx) {
  let proposal_spreadsheet = new_proposal.proposal_spreadsheet;
  let metadata = new $proposal.Questionnaire(
    (() => {
      let _pipe = proposal_spreadsheet.xlsx;
      let _pipe$1 = $option.map(_pipe, (a) => { return a.data; });
      let _pipe$2 = $option.map(
        _pipe$1,
        (_capture) => { return $list.map(_capture, (a) => { return a.name; }); },
      );
      return $option.unwrap(_pipe$2, toList([]));
    })(),
    $list.flat_map(
      proposal_spreadsheet.columns_type,
      (column) => {
        let name = column[0];
        let content = column[1];
        let _pipe = content;
        let _pipe$1 = $dict.to_list(_pipe);
        let _pipe$2 = $list.filter(
          _pipe$1,
          (a) => { return $list.contains(valid_columns, a[1]); },
        );
        return $list.map(
          _pipe$2,
          (a) => {
            let kind = (() => {
              let $ = a[1];
              if ($ instanceof AnswerColumn) {
                return new $question.Long();
              } else if ($ instanceof YesNoColumn) {
                return new $question.YesNo();
              } else if ($ instanceof MultipleChoiceColumn) {
                return new $question.MultipleChoice();
              } else {
                throw makeError(
                  "panic",
                  "data/ui/new_proposal",
                  168,
                  "",
                  "Unexpected behaviour",
                  {}
                )
              }
            })();
            return new $proposal.QuestionnaireResponse(name, a[0], kind);
          },
        );
      },
    ),
    new_proposal.qualification_matrix,
    xlsx.data,
    new_proposal.languages,
  );
  return $proposal.encode(
    new $proposal.Proposal(
      "",
      new $proposal.Display("questionnaire"),
      org_id,
      new_proposal.name,
      new_proposal.due_date,
      user_id,
      new_proposal.client,
      new_proposal.collaborators,
      0,
      new $status.InProgress(),
      metadata,
      $birl.utc_now(),
      new $proposal.Sheets(),
      new $option.None(),
    ),
  );
}

export function encode(new_proposal, org_id, user_id) {
  let $ = new_proposal.proposal_spreadsheet.xlsx;
  if ($ instanceof $option.None) {
    return new Error(undefined);
  } else {
    let xlsx = $[0];
    let _pipe = $json.object(
      toList([
        ["proposal", encode_proposal(new_proposal, org_id, user_id, xlsx)],
        ["spreadsheet", $spreadsheet.encode(xlsx)],
        ["questions", encode_questions(xlsx.data, new_proposal)],
      ]),
    );
    let _pipe$1 = $json.to_string(_pipe);
    let _pipe$2 = $form_data.to_form_data(_pipe$1, xlsx.blob);
    return new Ok(_pipe$2);
  }
}
